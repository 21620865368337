
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import mpegts from "mpegts.js";

@Options({})
export default class extends Vue {
  @Prop({ required: true })
  private options: any;

  private player: any;

  private loading = true;

  @Watch("options", { deep: true })
  private onOptionsChange(): void {
    if (this.player) {
      this.player_destroy();
    }
    this.createMyplayer();
  }

  mounted() {
    this.createMyplayer();
  }

  beforeUnmount(): void {
    if (this.player) {
      this.player_destroy();
    }
  }

  private player_destroy() {
    this.player.pause();
    this.player.unload();
    this.player.detachMediaElement();
    this.player.destroy();
    this.player = null;
  }

  private createMyplayer(): void {
    if (mpegts.getFeatureList().mseLivePlayback) {
      var videoElement = document.getElementById(
        "videoElement"
      ) as HTMLMediaElement;
      this.player = mpegts.createPlayer({ ...this.options });
      this.player.attachMediaElement(videoElement);
      this.player.load();
      this.player.play().then(() => {
        this.loading = false;
      });
    }
  }
}
